@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    /* For Firefox Browser */
    .scrollbar {
        scrollbar-width: thin;
        scrollbar-color: #858585 #f7f4ed;
    }

    /* For Chrome, EDGE, Opera, Others */
    .scrollbar::-webkit-scrollbar {
        width: 12px;
    }
    .scrollbar::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: #f7f4ed;
    }
    .scrollbar::-webkit-scrollbar-thumb {
        background: #858585;
        border-radius: 100vh;
        border: 3px solid #f6f7ed;
    }
    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #858585;
    }
}
